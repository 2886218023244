import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, ReferenceInput,List,Datagrid,TextField,ReferenceField, Edit } from 'react-admin';
import authProvider from './authProvider';


export const MessageAdminCreate = props => {
    React.useEffect(() => {
        authProvider
            .getPermissions()
            .then((permissions) => {
                setUserRole(permissions); // Supposons que `permissions` retourne directement le rôle
                console.log("Rôle de l'utilisateur :", permissions);
            })
            .catch(() => {
                console.log("Erreur lors de la récupération des permissions.");
                setUserRole(null); // Aucun rôle défini si erreur
            });
    }, []);
    const [userRole, setUserRole] = React.useState(null);

    if (userRole !== 'admin') {
        return null;
    }
    <Create {...props}>
        <SimpleForm>
            <TextInput source="titre" label="Titre" />
            <TextInput source="contenu" label="Contenu" multiline />
            <ReferenceInput source="destinataire_id" reference="membres" label="Destinataire">
                <SelectInput optionText="nom" />
            </ReferenceInput>
            <ReferenceInput source="envoye_par_id" reference="membres" label="Envoyé par">
                <SelectInput optionText="nom" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
};

export const MessageAdminEdit = props => {
    React.useEffect(() => {
        authProvider
            .getPermissions()
            .then((permissions) => {
                setUserRole(permissions); // Supposons que `permissions` retourne directement le rôle
                console.log("Rôle de l'utilisateur :", permissions);
            })
            .catch(() => {
                console.log("Erreur lors de la récupération des permissions.");
                setUserRole(null); // Aucun rôle défini si erreur
            });
    }, []);
    const [userRole, setUserRole] = React.useState(null);

    if (userRole !== 'admin') {
        return null;
    }
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="titre" label="Titre" />
            <TextInput source="contenu" label="Contenu" multiline />
            <ReferenceInput source="destinataire_id" reference="membres" label="Destinataire">
                <SelectInput optionText="nom" />
            </ReferenceInput>
            <ReferenceInput source="envoye_par_id" reference="membres" label="Envoyé par">
                <SelectInput optionText="nom" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
};
export const MessageAdminList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="titre" label="Titre" />
            <TextField source="contenu" label="Contenu" />
            <ReferenceField source="destinataire_id" reference="membres" label="Destinataire">
                <TextField source="nom" />
            </ReferenceField>
            <ReferenceField source="envoye_par_id" reference="membres" label="Envoyé par">
                <TextField source="nom" />
            </ReferenceField>
        </Datagrid>
    </List>
);