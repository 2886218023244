import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  FileInput,
  DateField,
  FileField,
  ImageField,
  useDeleteWithConfirmController,
  Confirm,
  useNotify,
  useRefresh,
  useRedirect,
  Edit
} from 'react-admin';
import environment from './config/environment';

const DeleteWithConfirmButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { open, setOpen, loading, handleDelete } = useDeleteWithConfirmController({
    resource: 'article',
    record,
    redirect: 'list',
    onSuccess: () => {
      notify('Article supprimé', { type: 'info' });
      refresh();
    },
    onFailure: (error) => {
      notify(`Erreur: ${error.message}`, { type: 'warning' });
    },
  });

  return (
    <>
      <DeleteButton record={record} onClick={() => setOpen(true)} />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Confirmation de suppression"
        content="Êtes-vous sûr de vouloir supprimer cet article ?"
        onConfirm={handleDelete}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export const ArticleList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="titre" />
      <TextField source="description" />
      <ImageField source="media_images" label="Image" />
      <FileField source="media_videos" label="Video" />
      <TextField source="auteur" />
      <DateField source="date" />
      <TextField source="contenu" />
      <TextField source="type_article_id" label="Type d'Article" />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
);

export const ArticleCreate = (props) => {
  const [typeChoices, setTypeChoices] = useState([]);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await fetch(`${environment.apiUrl}/type_article`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const types = await response.json();
        setTypeChoices(types.map((type) => ({ id: type.id, name: type.nom })));
      } catch (error) {
        console.error('Failed to fetch type articles:', error);
      }
    };
    fetchTypes();
  }, []);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('auteur', values.auteur);
    formData.append('contenu', values.contenu);
    formData.append('description', values.description);
    formData.append('titre', values.titre);
    formData.append('type_article_id', values.type_article_id);
    if (values.media_images instanceof File) {
      formData.append(
        'media_images',
        values.media_images,
        values.media_images.name,
      );
    } else if (
      values.media_images &&
      values.media_images.rawFile instanceof File
    ) {
      formData.append(
        'media_images',
        values.media_images.rawFile,
        values.media_images.rawFile.name,
      );
    }
    if (values.media_videos instanceof File) {
      formData.append(
        'media_videos',
        values.media_videos,
        values.media_videos.name,
      );
    } else if (
      values.media_videos &&
      values.media_videos.rawFile instanceof File
    ) {
      formData.append(
        'media_videos',
        values.media_videos.rawFile,
        values.media_videos.rawFile.name,
      );
    }
    try {
      const response = await fetch(`${environment.apiUrl}/article`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      notify('Article créé avec succès', { type: 'info' });
      redirect('list', '/article');
      refresh();
    } catch (error) {
      notify(`Erreur: ${error.message}`, { type: 'warning' });
      console.error('Failed to create article:', error);
    }
  };

  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput source="titre" />
        <TextInput source="description" />
        <FileInput source="media_images" accept="image/*" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="media_videos" accept="video/*" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="auteur" />
        <SelectInput
          source="type_article_id"
          choices={typeChoices}
          optionText="name"
          optionValue="id"
        />
        <TextInput source="contenu" multiline />
      </SimpleForm>
    </Create>
  );
};

export const ArticleEdit = (props) => {
  const [typeChoices, setTypeChoices] = useState([]);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await fetch(`${environment.apiUrl}/type_article`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const types = await response.json();
        setTypeChoices(types.map((type) => ({ id: type.id, name: type.nom })));
      } catch (error) {
        console.error('Failed to fetch type articles:', error);
      }
    };
    fetchTypes();
  }, []);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('auteur', values.auteur);
    formData.append('contenu', values.contenu);
    formData.append('description', values.description);
    formData.append('titre', values.titre);
    formData.append('type_article_id', values.type_article_id);
    if (values.media_images instanceof File) {
      formData.append(
        'media_images',
        values.media_images,
        values.media_images.name,
      );
    } else if (
      values.media_images &&
      values.media_images.rawFile instanceof File
    ) {
      formData.append(
        'media_images',
        values.media_images.rawFile,
        values.media_images.rawFile.name,
      );
    }
    if (values.media_videos instanceof File) {
      formData.append(
        'media_videos',
        values.media_videos,
        values.media_videos.name,
      );
    } else if (
      values.media_videos &&
      values.media_videos.rawFile instanceof File
    ) {
      formData.append(
        'media_videos',
        values.media_videos.rawFile,
        values.media_videos.rawFile.name,
      );
    }
    try {
      const response = await fetch(`${environment.apiUrl}/article/${values.id}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      notify('Article modifié avec succès', { type: 'info' });
      redirect('list', '/article');
      refresh();
    } catch (error) {
      notify(`Erreur: ${error.message}`, { type: 'warning' });
      console.error('Failed to edit article:', error);
    }
  };

  return (
    <Edit {...props} onSubmit={handleSubmit}>
      <SimpleForm >
        <TextInput source="id" disabled />
        <TextInput source="titre" />
        <TextInput source="description" />
        <FileInput source="media_images" accept="image/*" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="media_videos" accept="video/*" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="auteur" />
        <SelectInput
          source="type_article_id"
          choices={typeChoices}
          optionText="name"
          optionValue="id"
        />
        <TextInput source="contenu" multiline />
      </SimpleForm>
    </Edit>
  );
};
