import { useLogin, useNotify } from 'react-admin';
import { useState } from 'react';
import './LoginPage.css';

const LoginPage = () => {
  const login = useLogin();
  const notify = useNotify();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const submit = (e) => {
    e.preventDefault();
    login({ email, password })
      .then(() => setError(''))
      .catch(() => {
        setError('Identifiant ou mot de passe incorrect');
        notify('Identifiant ou mot de passe incorrect');
      });
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={submit}>
        <h2>Connexion</h2>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
        />
        <button type="submit">Se connecter</button>
        {error && <p className="notify-message">{error}</p>}
      </form>
    </div>
  );
};

export default LoginPage;
