import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).access_token : null;
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).access_token : null;

    // Affiche les informations de l'utilisateur connecté dans la console
    const user = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).user : null;
    console.log('Utilisateur connecté:', user);

    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }else{
        console.log("le token n'existe pas : ",token)
    }
    
    return fetchUtils.fetchJson(url, options).catch(error => {
        console.error('Erreur réseau :', error);
        throw error;
    });
};

const dataProvider = {
  
    getList: (resource, params) => {
    let url;

    if (resource === "ordersAdmin") {
      // Si la ressource est "ordersAdmin", utiliser la route spécifique de filtrage
      const query = {
        page: params.pagination.page,
        perPage: params.pagination.perPage,
        sort: params.sort.field,
        order: params.sort.order,
        filter: JSON.stringify(params.filter),
      };
      url = `${apiUrl}/orderByOrderItem?${stringify(query)}`;
    } else {
      // Utiliser la route par défaut pour les autres ressources
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
    }

    return httpClient(url).then(({ headers, json }) => {
      const data = Array.isArray(json) ? json : json.data;

      if (!Array.isArray(data)) {
        console.error("La réponse n'est pas un tableau valide", json);
        throw new Error("La réponse n'est pas un tableau valide");
      }

      return {
        data: data.map(item => ({
          ...item,
          id: item.id || item.identifier || item.someOtherKey, // Assure-toi que 'id' existe
        })),
        total: data.length, // Nombre d'éléments dans la réponse
      };
    }).catch(error => {
      console.error("Erreur lors de la récupération des données :", error);
      throw error;
    });
  },

  getOne: (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;

    return httpClient(url).then(({ json }) => {
      return {
        data: json,
      };
    });
  },

  getMany: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}?filter=${JSON.stringify({ id: params.ids })}`)
      .then(({ json }) => ({
        data: Array.isArray(json) ? json : [json], // Assure-toi que c'est toujours un tableau
      }));
  },

  getManyReference: (resource, params) => {
    const query = {
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      return {
        data: json,
        total: json.length,
      };
    });
  },


    update: (resource, params) => {
      const token = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).access_token : null;
        const url = `${apiUrl}/${resource}/${params.id}`;
        const formData = new FormData();

        Object.keys(params.data).forEach((key) => {
            const value = params.data[key];
            if (value && value.rawFile) {
                formData.append(key, value.rawFile, value.rawFile.name);
            } else if (value !== null && value !== undefined) {
                formData.append(key, value);
            }
        });
        formData.append('_method', 'PUT');

        return fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        })
            .then(response => response.json())
            .then(json => ({ data: json }));
    },

    create: (resource, params) => {
      const token = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).access_token : null;
        const url = `${apiUrl}/${resource}`;
        const formData = new FormData();
        Object.keys(params.data).forEach((key) => {
            if (params.data[key] && params.data[key].rawFile) {
                formData.append(key, params.data[key].rawFile);
            } else {
                formData.append(key, params.data[key]);
            }
        });

        return fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        })
            .then(response => response.json())
            .then(json => ({ data: json }));
    },

    delete: (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}`;
        return httpClient(url, { method: 'DELETE' }).then(({ json }) => ({
            data: json,
        }));
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, { method: 'DELETE' }).then(({ json }) => ({
            data: json,
        }));
    },
};

export default dataProvider;
